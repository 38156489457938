import { useRun } from "@insightfulscience/smart-react/hocs/DiPageHoC";
import { areTokensValid } from "./status";

const useIsAuthorized = () => {
	const isAuthorized = useRun(() => ({ storageService: { token, refreshToken } }) =>
		areTokensValid(token, refreshToken),
	);

	return isAuthorized();
};

export default useIsAuthorized;
