import React from "react";
import { bool, node, string } from "prop-types";
import Layout from "@insightfulscience/atomic-react/Layout";
import SiteFooter from "../SiteFooter";
import SiteHeader from "../SiteHeader";

const SiteLayout = ({ bodyClassName = "", children, ...props }) => {
	return (
		<Layout>
			<Layout.Header>
				<SiteHeader {...props} />
			</Layout.Header>
			<Layout.Body className={bodyClassName}>{children}</Layout.Body>
			<Layout.Footer>
				<SiteFooter />
			</Layout.Footer>
		</Layout>
	);
};

SiteLayout.propTypes = {
	bodyClassName: string,
	children: node,
	hero: bool,
};

export default SiteLayout;
