import React from "react";
import { bool } from "prop-types";
import Header from "@insightfulscience/atomic-react/Header";
import Button from "@insightfulscience/atomic-react/Button";
import Icon from "@insightfulscience/atomic-react/Icon";
import Navbar from "@insightfulscience/atomic-react/Navbar";
import NavbarItem from "@insightfulscience/atomic-react/Navbar/Item";
import NavbarItemLink from "@insightfulscience/atomic-react/Navbar/Link";
import NextLink from "@insightfulscience/smart-react/NextLink";
import { useLocale } from "@insightfulscience/i18";
import NavbarDropdownTitle from "@insightfulscience/atomic-react/NavbarDropdown/Title";
import NavbarDropdown from "@insightfulscience/atomic-react/NavbarDropdown";
import NavbarDropdownMenu from "@insightfulscience/atomic-react/NavbarDropdown/Menu";
import NavbarDropdownMenuItem from "@insightfulscience/atomic-react/NavbarDropdown/MenuItem";
import Block from "@insightfulscience/atomic-react/Block";
import TopBar from "@insightfulscience/topbar";
import routes from "../routes";
import useIsAuthorized from "../services/authService/hooks";
import { prerenderedLogo } from "../prerendered-logo";

const SiteHeader = ({ hero = false, isBorderBottomNone = false }) => {
	const [t] = useLocale("header");
	const isAuthorized = useIsAuthorized();

	return (
		<>
			<TopBar brandCode="sg" />
			<Header hero={hero} isBorderBottomNone={isBorderBottomNone} logo={prerenderedLogo}>
				<Block utility={{ lg: { display: "flex", justify: "end" } }} sizing={{ lg: { w: "full" } }}>
					<Navbar nav="primary" type={1} inverted>
						<NavbarItem>
							<NextLink Component={NavbarItemLink} data-qa-selector="features-H" href="/features">
								{t("FEATURES")}
							</NextLink>
						</NavbarItem>
						<NavbarItem>
							<NextLink Component={NavbarItemLink} data-qa-selector="plasmids-H" href="/plasmids">
								{t("PLASMIDS")}
							</NextLink>
						</NavbarItem>
						<NavbarDropdown isSecondaryNav>
							<NavbarDropdownTitle triangleProps={{ color: "2", isOpenColor: "3" }}>
								{t("RESOURCES")}
							</NavbarDropdownTitle>
							<NavbarDropdownMenu align="right">
								<NavbarDropdownMenuItem>
									<NextLink
										Component={NavbarDropdownMenuItem.Link}
										data-qa-selector="getting-started"
										href="/series/getting-started"
									>
										{t("GETTING_STARTED")}
									</NextLink>
								</NavbarDropdownMenuItem>
								<NavbarDropdownMenuItem>
									<NextLink
										Component={NavbarDropdownMenuItem.Link}
										data-qa-selector="snapgene-academy"
										href="/resources"
									>
										{t("SNAGENE_ACADEMY")}
									</NextLink>
								</NavbarDropdownMenuItem>
								<NavbarDropdownMenuItem>
									<NavbarDropdownMenuItem.Link
										data-qa-selector="user-guides-header"
										href="https://support.snapgene.com/hc/en-us/categories/10304176221716"
									>
										{t("USER_GUIDES")}
									</NavbarDropdownMenuItem.Link>
								</NavbarDropdownMenuItem>
							</NavbarDropdownMenu>
						</NavbarDropdown>
						<NavbarItem>
							<NextLink
								Component={NavbarItemLink}
								data-qa-selector="support-H"
								href={routes.external.support}
							>
								{t("SUPPORT")}
							</NextLink>
						</NavbarItem>
						<NavbarItem>
							<NextLink Component={NavbarItemLink} data-qa-selector="pricing-H" href="/pricing">
								{t("HOW_TO_BUY")}
							</NextLink>
						</NavbarItem>
					</Navbar>
					<Navbar nav="secondary" type={1} inverted>
						<NavbarItem secondary>
							<NextLink
								Component={NavbarItemLink}
								data-qa-selector="sign-in-H"
								href={isAuthorized ? routes.myAccount : routes.login}
							>
								<Icon name="user" /> {t(isAuthorized ? "MY_ACCOUNT" : "SIGNIN")}
							</NextLink>
						</NavbarItem>
						<NavbarItem secondary className="secondary-nav__item-trial">
							<NextLink
								Component={Button.Link}
								data-qa-selector="free-trial-H"
								size="s"
								href="/free-trial"
							>
								<Icon name="arrow-to-bottom" /> Free Trial
							</NextLink>
						</NavbarItem>
					</Navbar>
				</Block>
			</Header>
		</>
	);
};

SiteHeader.propTypes = {
	hero: bool,
	isBorderBottomNone: bool,
};

export default SiteHeader;
